import { FaGenderless } from "react-icons/fa";
import { GiDiploma, GiBigDiamondRing } from 'react-icons/gi'
import { IoPersonOutline } from 'react-icons/io5'
import { BsCalendar4Week, BsEnvelope, BsPhone, BsGlobe } from 'react-icons/bs'
import { HiOutlineIdentification} from 'react-icons/hi'
import { RiUserLocationLine } from 'react-icons/ri'
import PreviewTableFour from "./previewtables/PreviewTableFour";
import PreviewTableOne from "./previewtables/PreviewTableOne";
import PreviewTableThree from "./previewtables/PreviewTableThree";
import PreviewTableTwo from "./previewtables/PreviewTableTwo";

const PreviewApplication = ({ intake, files, work, tertiary, ordLevel, mode, level, programmeOne, programmeTwo, programmeThree, title, forenames, surname, dob, gender, maritalStatus, nationality, idNumber, email, phoneNumber, address }) => {
    return (
        <>
            <div className="py-6">
                <p className="text-teal font-semibold font-poppins text-2xl mb-4">Complete Application</p>
            </div>
            <div className="my-6 lg:my-6">
                <p className="text-teal font-semibold font-poppins text-lg mb-5">Selected Programmes</p>
                <div className="grid md:grid-cols-3 gap-5 lg:w-8/12">
                    <p className="flex items-center">
                        <GiDiploma fontSize="1.8rem" className="mr-3 text-gray-400" />
                        <span className="text-sm text-gray-600 text-bold">{programmeOne}</span>
                    </p>
                    <p className="flex items-center">
                        <GiDiploma fontSize="1.8rem" className="mr-3 text-gray-400" />
                        <span className="text-sm text-gray-600 text-bold">{programmeTwo}</span>
                    </p>
                    <p className="flex items-center">
                        <GiDiploma fontSize="1.8rem" className="mr-3 text-gray-400" />
                        <span className="text-sm text-gray-600 text-bold">{programmeThree}</span>
                    </p>
                </div>
            </div>
            <div className="my-6 lg:my-12">
                <div className="grid md:grid-cols-3 gap-5 lg:w-10/12">
                    <div>
                        <p className="text-teal font-semibold font-poppins text-lg">Level:</p>
                        <span className="text-sm text-gray-600 text-bold">{level}</span>
                    </div>
                    <div>
                        <p className="text-teal font-semibold font-poppins text-lg">Mode of Study:</p>
                        <span className="text-sm text-gray-600 text-bold">{mode}</span>
                    </div>
                    <div>
                        <p className="text-teal font-semibold font-poppins text-lg">Intake:</p>
                        <span className="text-sm text-gray-600 text-bold">{intake}</span>
                    </div>
                </div>
            </div>
            <div className="my-6 lg:my-12">
                <p className="text-teal font-semibold font-poppins text-lg mb-5">Personal Information</p>
                <div className="grid gap-5 md:grid-cols-3 md:gap-7 lg:gap-9 lg:w-10/12">
                    <p className="flex items-center">
                        <IoPersonOutline fontSize="1.7rem" className="mr-3 text-gray-600" />
                        <span className="text-sm text-gray-600 text-bold">{title} {forenames} {surname}</span>
                    </p>
                    <p className="flex items-center">
                        <BsCalendar4Week fontSize="1.7rem" className="mr-3 text-gray-600" />
                        <span className="text-sm text-gray-600 text-bold">Date of Birth: <b>{dob}</b></span>
                    </p>
                    <p className="flex items-center">
                        <FaGenderless fontSize="1.7rem" className="mr-3 text-gray-600" />
                        <span className="text-sm text-gray-600 text-bold">Gender: <b>{gender}</b></span>
                    </p>
                    <p className="flex items-center">
                        <GiBigDiamondRing fontSize="1.7rem" className="mr-3 text-gray-600" />
                        <span className="text-sm text-gray-600 text-bold">Marital Status: <b>{maritalStatus}</b></span>
                    </p>

                    <p className="flex items-center">
                        <BsGlobe fontSize="1.7rem" className="mr-3 text-gray-600" />
                        <span className="text-sm text-gray-600 text-bold">Nationality: <b>{nationality}</b></span>
                    </p>
                    <p className="flex items-center">
                        <HiOutlineIdentification fontSize="1.7rem" className="mr-3 text-gray-600" />
                        <span className="text-sm text-gray-600 text-bold">I.D Number: <b>{idNumber}</b></span>
                    </p>
                    <p className="flex items-center">
                        <BsEnvelope fontSize="1.7rem" className="mr-3 text-gray-600" />
                        <span className="text-sm text-gray-600 text-bold">Email: <b>{email}</b></span>
                    </p>
                    <p className="flex items-center">
                        <BsPhone fontSize="1.7rem" className="mr-3 text-gray-600" />
                        <span className="text-sm text-gray-600 text-bold">Phone Number: <b>{phoneNumber}</b></span>
                    </p>
                    <p className="flex items-center">
                        <RiUserLocationLine fontSize="1.7rem" className="mr-3 text-gray-600" />
                        <span className="text-sm text-gray-600 text-bold">Address: <b>{address}</b></span>
                    </p>
                </div>
            </div>
            {
                ordLevel.length !== 0 && (
                    <>
                        <p className="text-teal font-semibold font-poppins text-lg my-6">'O' level Examination Results</p>
                        <PreviewTableOne ordLevel={ordLevel} />
                    </>
                )
            }
            {
                tertiary.length !== 0 && (
                    <>
                        <p className="text-teal font-semibold font-poppins text-lg my-6">Tertiary/College Examination Results</p>
                        <PreviewTableTwo tertiary={tertiary} />
                    </>
                )
            }
            {
                work.length !== 0 && (
                    <>
                        <p className="text-teal font-semibold font-poppins text-lg my-6">Work/Employment</p>
                        <PreviewTableThree work={work} />
                    </>
                )
            }
            {
                files && (
                    <>
                        {
                            files.length !== 0 && (
                                <>
                                    <p className="text-teal font-semibold font-poppins text-lg mb-5">Document</p>
                                    <PreviewTableFour files={files} />
                                </>
                            )
                        }
                    </>
                )
            }
        </>
    );
}

export default PreviewApplication;