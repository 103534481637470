import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import { BsCloudUpload } from 'react-icons/bs';
import { BiMailSend } from 'react-icons/bi'
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress'

const Modal = ({ setOpenModal, progressOne, docSent, setDocSent, mailSent }) => {


    React.useEffect(() => {
        if (document.getElementById('barOne')) {
            document.getElementById('barOne').style.width = `${progressOne + '%'}`
        }

    }, [progressOne])


    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <div id="popup-modal" tabIndex="-1" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal md:h-full justify-center items-center" aria-hidden="true">
                    <div className="relative p-4 w-full max-w-md h-full md:h-auto mx-auto ">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 top-44 md:top-40 lg:top-20">
                            <button
                                id="closeBtn"
                                onClick={() => setOpenModal(false)}
                                type="button"
                                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-toggle="popup-modal"
                            >
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            {
                                docSent === false && (
                                    <div className="p-8 text-center">
                                        <BsCloudUpload size="2.5rem" className="text-gray-500 mx-auto mb-4 w-14 h-14" />
                                        <h3 className="mb-10 text-lg font-normal text-gray-500 dark:text-gray-400">Uploading Document... </h3>
                                        <div className="flex justify-between">
                                            <span className="text-base font-medium text-teal dark:text-white">Progress</span>
                                            <span className="text-sm font-medium text-teal dark:text-white">{Math.round(progressOne)}%</span>
                                        </div>
                                        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mb-6">
                                            <div className="bg-teal h-2.5 rounded-full" id="barOne"></div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                docSent === true && (
                                    <div className="p-8 text-center">
                                        <BsCloudUpload size="2.5rem" className="text-gray-500 mx-auto mb-4 w-14 h-14" />
                                        <Box sx={{ width: '100%' }}  >
                                            <LinearProgress color='success' />
                                        </Box>
                                        <h3 className="mb-10 text-lg font-normal text-gray-500 dark:text-gray-400 mt-9">Saving your Application Data to our database.</h3>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </Backdrop>
        </>

    );
}

export default Modal;