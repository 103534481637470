import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FaTrash } from 'react-icons/fa';

const TableThree = ({ work , setWork }) => {

    const removeJob = (job) => {
        
        let newWork = work.filter((works) => {
            return works.occupation !== job.occupation ;
        });
        setWork( newWork);
    }

    return ( 

        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Period</TableCell>
                        <TableCell>Job Title</TableCell>
                        <TableCell>Employer</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {
                    work && work.map(job => (
                        <TableRow  key={job.employerName}>
                            <TableCell component="th" scope="row" >{job.fromDate} to {job.toDate}</TableCell>
                            <TableCell>{job.occupation}</TableCell>
                            <TableCell>{job.employerName}</TableCell>
                            <TableCell>{job.workAddress}</TableCell>
                            <TableCell>
                                <FaTrash 
                                    color="#af0e19" 
                                    onClick={() => removeJob(job)}
                                    className="cursor-pointer"
                                />
                                </TableCell>
                        </TableRow>

                    ))
                }    
                </TableBody>
            </Table>
        </TableContainer>
     );
}
 
export default TableThree;