import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FaTrash } from 'react-icons/fa';

const TableOne = ({ ordLevel, setOrdLevel }) => {

    const removeLevel = (levelToDelete) => {
        
        let newOrdLevel = ordLevel.filter((level) => {
            return level.subject !== levelToDelete.subject ;
        });
        setOrdLevel(newOrdLevel);
    }
    
    return ( 

        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Subject</TableCell>
                        <TableCell>Grade</TableCell>
                        <TableCell>Exam Board</TableCell>
                        <TableCell>Month</TableCell>
                        <TableCell>Year</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {
                    ordLevel && ordLevel.map(level => (
                        <TableRow  key={level.subject}>
                            <TableCell component="th" scope="row" >{level.subject}</TableCell>
                            <TableCell>{level.grade}</TableCell>
                            <TableCell>{level.board}</TableCell>
                            <TableCell>{level.month}</TableCell>
                            <TableCell>{level.year}</TableCell>
                            <TableCell>
                                <FaTrash 
                                    color="#af0e19" 
                                    onClick={() => removeLevel(level)} 
                                    className="cursor-pointer"
                                />
                                </TableCell>
                        </TableRow>

                    ))
                }    
                </TableBody>
            </Table>
        </TableContainer>
     );
}
 
export default TableOne;