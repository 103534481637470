import React from 'react'
import AdminLayout from './AdminLayout'
import ApplicationTable from './tables/ApplicationTable'


function Applications() {
  return (
    <AdminLayout>
        <div className="py-6">
            <p className="text-teal font-semibold font-poppins text-2xl mb-4">Applications</p>
        </div>
        <ApplicationTable />
    </AdminLayout>
  )
}

export default Applications