import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableTwo from './tables/TableTwo';

const PageFour = ({ level, tertiary, setTertiary, tertiaryLevel, setTertiaryLevel, course, setCourse, tertiaryYear, setTertiaryYear }) => {

    const addTertiary = () => {

        for (let i = 0; i < tertiary.length; i++) {
            if(tertiary[i].course === course)  {
                toast.error('Sorry, you cannot duplicate course.')
                return;
            }         
        }

        if(tertiaryLevel !== '' && course !== '' && tertiaryYear !== '') {
            let data = {
                tertiaryLevel,
                course,
                tertiaryYear

            }
            setTertiary([...tertiary, data]);
            setTertiaryYear('');
            setCourse('');
            setTertiaryLevel('');
        } else {
            toast.error('Please provide all the details.');

        }
    }

    useEffect(() => {
        if (level !== "National Diploma") {
            toast.success('For National Foundation Certificate or National Certificate tertiary qualifications are not a requirement, hence kindly skip to next step.');
        }
    }, [])

    return ( 
        <>
            <div className="py-6">
                <p className="text-teal font-semibold font-poppins text-2xl mb-4">Tertiary / College Examination Results</p>
            </div>
            <div className="grid gap-10 sm:grid-cols-2 md:grid-cols-4 md:gap-12 lg:gap-x-20">
                <FormControl fullWidth variant="standard" className="sm:col-span-1 md:col-span-1">
                    <InputLabel id="level">Level Attained</InputLabel>
                        <Select
                        labelid="level"
                        id="level-select"
                        onChange={(e) => setTertiaryLevel(e.target.value)} 
                        value={tertiaryLevel}
                        label="Level Attained"
                        name="level"
                    >
                        <MenuItem value="National Certificate">National Certificate</MenuItem>
                        <MenuItem value="National Diploma">National Diploma</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                    </Select>
                </FormControl>
                <TextField 
                    labelid="course"
                    id="course-select"
                    variant="standard"
                    onChange={(e) => setCourse(e.target.value)} 
                    value={course}
                    label="Course"
                    name="course"
                    type="text"
                    className="sm:col-span-1 md:col-span-2"
                />
                <TextField 
                    labelid="year"
                    id="year-select"
                    variant="standard"
                    onChange={(e) => setTertiaryYear(e.target.value)} 
                    value={tertiaryYear}
                    label="Year"
                    name="year"
                    type="text"
                    className="sm:col-span-1 md:col-span-1"
                />
            </div>
            <div className="mt-12 mb-6">
                <Button 
                    variant="outlined" 
                    startIcon={<AddCircleOutline />} 
                    size="sm"
                    onClick={addTertiary}
                    color="primary"              
                    >
                    Add Course
                </Button>
            </div>
            {
                tertiary.length !== 0 && <TableTwo tertiary={tertiary} setTertiary={setTertiary} />
            }     
            <ToastContainer />   
        </>
     );
}
 
export default PageFour;