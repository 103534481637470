import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FaTrash } from 'react-icons/fa';

function PreviewTableOne({ ordLevel }) {
  return (
    <TableContainer>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Subject</TableCell>
                    <TableCell>Grade</TableCell>
                    <TableCell>Exam Board</TableCell>
                    <TableCell>Month</TableCell>
                    <TableCell>Year</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {
                ordLevel && ordLevel.map(level => (
                    <TableRow  key={level.subject}>
                        <TableCell component="th" scope="row" >{level.subject}</TableCell>
                        <TableCell>{level.grade}</TableCell>
                        <TableCell>{level.board}</TableCell>
                        <TableCell>{level.month}</TableCell>
                        <TableCell>{level.year}</TableCell>
                    </TableRow>
                ))
            }    
            </TableBody>
        </Table>
    </TableContainer>
  )
}

export default PreviewTableOne