import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

export const firebaseConfig = {
  apiKey: "AIzaSyDCvLyFiE61ROgXzZM1FTaKKJvn6keUYR0",
  authDomain: "biccetportal.firebaseapp.com",
  projectId: "biccetportal",
  storageBucket: "biccetportal.appspot.com",
  messagingSenderId: "218220369069",
  appId: "1:218220369069:web:028f9e52d8454f44e1e224"
  };

//init firebase app
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);

export default app;

