import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    isLoggedIn: false,
    email: null,
    userID: null
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        SET_ACTIVE_USER: (state, action) => {
            state.isLoggedIn = true;

            const { email, userID } = action.payload;
            state.email = email;
            state.userID = userID;
            console.log(state.isLoggedIn, state.email, state.userID);
        },
        REMOVE_ACTIVE_USER: (state, action) => {
            state.isLoggedIn = false;
            state.email = null;
            state.userID = null;
            console.log(state.isLoggedIn, state.email, state.userID);
        }
    }
});

export const { SET_ACTIVE_USER, REMOVE_ACTIVE_USER } = authSlice.actions

export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectEmail = (state) => state.auth.email;

export default authSlice.reducer