import React from 'react'
import AdminLayout from './AdminLayout'
import PendingTable from './tables/PendingTable'

function ApplicationPending() {
  return (
    <AdminLayout>
      <div className="py-6">
        <p className="text-teal font-semibold font-poppins text-2xl mb-4">Pending Applications</p>
      </div>
      <PendingTable />
    </AdminLayout>
  )
}

export default ApplicationPending