import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Layout from './Layout';
import Login from './admin/LogIn'
import Dashboard from './admin/Dashboard';
import Applications from './admin/Applications';
import ApplicationAccepted from './admin/ApplicationAccepted';
import ApplicationPending from './admin/ApplicationPending';
import Application from './admin/Application';


const theme = createTheme({
  palette: {
    primary: {
      main: '#006a62',
    }
  }
})

function App() {

  return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <BrowserRouter>
              <Routes>
                <Route path="/" element={<Navigate to="/online-application" />} />
                <Route path="/onlineapplication" element={<Navigate to="/online-application" />} />
                <Route path="/online-application" element={<Layout />} />
                <Route path="/login" element={<Login />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/applications" element={<Applications />} />
                <Route path="/applications-accepted" element={<ApplicationAccepted />} />
                <Route path="/applications-pending" element={<ApplicationPending />} />
                <Route path="/application/:id" element={<Application />} />
              </Routes>
          </BrowserRouter>
        </div>
      </ThemeProvider>
  );
}

export default App;
