import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FaTrash } from 'react-icons/fa';

const PreviewTableFour = ({ files, setFiles}) => {

    return ( 
        <TableContainer >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Selected File</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{files.name}</TableCell>
                        
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
     );
}
 
export default PreviewTableFour;