import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FaTrash } from 'react-icons/fa';

const PreviewTableTwo = ({ tertiary }) => {
    
    return ( 

        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Level Attained</TableCell>
                        <TableCell>Course Title</TableCell>
                        <TableCell>Year Obtained</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {
                    tertiary && tertiary.map(record => (
                        <TableRow  key={record.course}>
                            <TableCell component="th" scope="row" >{record.tertiaryLevel}</TableCell>
                            <TableCell>{record.course}</TableCell>
                            <TableCell>{record.tertiaryYear}</TableCell>
                        </TableRow>

                    ))
                }    
                </TableBody>
            </Table>
        </TableContainer>
     );
}
 
export default PreviewTableTwo;