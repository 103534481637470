import React from 'react'
import AdminLayout from './AdminLayout'
import Stats from './dashboard/Stats';

function Dashboard() {
  return (
    <AdminLayout>
        <div className="py-6 md:py-10" >
            <Stats />
        </div>
    </AdminLayout>
  )
}

export default Dashboard