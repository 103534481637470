import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const PageTwo = ({ title, setTitle, forenames, setForeNames, surname, setSurname, dob, setDob, gender, setGender, setMaritalStatus, maritalStatus, nationality, setNationality, idNumber, setIdNumber, email, setEmail, phoneNumber, setPhoneNumber, address, setAddress }) => {
    return (
        <>
        
            <div className="py-6">
                <p className="text-teal font-semibold font-poppins text-2xl mb-4">Personal Information</p>
            </div>
            <div className="grid gap-10 sm:grid-cols-3 md:grid-cols-5 md:gap-16 lg:gap-x-24">
                <FormControl fullWidth variant="standard">
                    <InputLabel id="title">Title</InputLabel>
                    <Select
                        labelId="title"
                        id="title-select"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        label="Title"
                        name="title"
                    >
                        <MenuItem value="Mr">Mr</MenuItem>
                        <MenuItem value="Mrs">Mrs</MenuItem>
                        <MenuItem value="Miss">Miss</MenuItem>
                        <MenuItem value="Ms">Ms</MenuItem>
                        <MenuItem value="Dr">Dr</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    id="standard-basic"
                    label="First Name(s)"
                    variant="standard"
                    className="sm:col-span-2 md:col-span-2"
                    type="text"
                    onChange={(e) => setForeNames(e.target.value)}
                    value={forenames}
                />
                <TextField
                    id="standard-basic"
                    label="Surname"
                    variant="standard"
                    className="sm:col-span-2 md:col-span-2"
                    type="text"
                    onChange={(e) => setSurname(e.target.value)}
                    value={surname}
                />
            </div>
            <div className="grid gap-10 mt-16 sm:grid-cols-4 md:grid-cols-4 md:gap-16  lg:grid-cols-4 lg:gap-x-20">
                <FormControl fullWidth variant="standard" className="sm:col-span-2 md:col-span-2 lg:col-span-1">
                    <InputLabel id="gender">Gender</InputLabel>
                    <Select
                        labelId="gender"
                        id="gender-select"
                        onChange={(e) => setGender(e.target.value)}
                        value={gender}
                        label="Gender"
                        name="gender"
                    >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    id="standard-basic"
                    label="D.O.B (e.g 01 January 1990)"
                    variant="standard"
                    className="sm:col-span-2 md:col-span-2 lg:col-span-1"
                    type="text"
                    onChange={(e) => setDob(e.target.value)}
                    value={dob}
                />
                {/* <DatePickers className="sm:col-span-2 md:col-span-2 lg:col-span-1" setDob={setDob} dob={dob} /> */}
                <FormControl fullWidth variant="standard" className="sm:col-span-2 md:col-span-2 lg:col-span-1">
                    <InputLabel id="marital-status">Marital Status</InputLabel>
                    <Select
                        labelId="marital-status"
                        id="marital-status-select"
                        onChange={(e) => setMaritalStatus(e.target.value)}
                        value={maritalStatus}
                        label="Marital Status"
                        name="marital-status"
                    >
                        <MenuItem value="Single">Single</MenuItem>
                        <MenuItem value="Married">Married</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    id="standard-basic"
                    label="Nationality"
                    variant="standard"
                    className="sm:col-span-2 md:col-span-2 lg:col-span-1"
                    onChange={(e) => setNationality(e.target.value)}
                    value={nationality}
                    type="text"
                />
            </div>
            <div className="grid gap-10 mt-16 sm:grid-cols-4 md:grid-cols-4 md:gap-16 lg:grid-cols-4 lg:gap-x-20 items-end">
                <TextField
                    id="standard-basic"
                    label="I.D No. e.g 12-3456789A12"
                    variant="standard"
                    className="sm:col-span-2 md:col-span-2 lg:col-span-1"
                    onChange={(e) => setIdNumber(e.target.value)}
                    value={idNumber}
                    type="text"
                />
                <TextField
                    id="standard-basic"
                    label="Email"
                    variant="standard"
                    className="sm:col-span-2 md:col-span-2 lg:col-span-1"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    type="email"
                // error={!EmailValidator.validate(email)}
                />
                <TextField
                    id="standard-basic"
                    label="Phone Number"
                    variant="standard"
                    className="sm:col-span-2 md:col-span-2 lg:col-span-1"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    value={phoneNumber}
                    type="number"
                />
                <TextField
                    type="text"
                    label="Address"
                    variant="standard"
                    className="sm:col-span-2 md:col-span-2 lg:col-span-1"
                    multiline
                    rows={2}
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                />
            </div>
            <p className="text-sm text-gray-500 mt-14 text-bold">NB: All fields are required to procced to the next step.</p>
        </>
    );
}

export default PageTwo;