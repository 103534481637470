import logo from '../assets/biccet-logo.svg';
import TopBanner from './TopBanner';
import { Link } from "react-router-dom";

const Nav = () => {
  return ( 
        <>
        <TopBanner />
        <nav className="bg-gray-100 border-gray-200 dark:bg-gray-900">
            <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl px-4 md:px-6 py-2.5 gap-y-3">
                <Link to="/" className="flex items-center">
                    <img src={logo} className="mr-3 h-16 sm:h-12 lg:h-20" alt="BICCET logo" />
                    <span className="self-center text-xl text-teal font-semibold whitespace-nowrap dark:text-white">BICCET Online Application</span>
                </Link>
                <div className="flex items-center">
                    <a href="tel:+263 71 886 9572" className="mr-6 text-md font-medium text-gray-500 dark:text-white hover:underline">+263 71 886 9572</a>
                </div>
            </div>
        </nav>
        </>
     );
}
 
export default Nav;