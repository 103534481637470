import AdminLayout from "./AdminLayout";
import { FaCalendar, FaCheckCircle, FaGenderless, FaGraduationCap, FaPersonBooth } from "react-icons/fa";
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { CgFileDocument } from 'react-icons/cg'
import { GiDiploma, GiBigDiamondRing } from 'react-icons/gi'
import { IoPersonOutline } from 'react-icons/io5'
import { BsCalendar4Week, BsEnvelope, BsPhone, BsGlobe } from 'react-icons/bs'
import { HiOutlineIdentification} from 'react-icons/hi'
import { RiUserLocationLine } from 'react-icons/ri'
// import PreviewTableFour from "./PreviewTableFour";
// import PreviewTableOne from "./PreviewTableOne";
// import PreviewTableThree from "./PreviewTableThree";
// import PreviewTableTwo from "./PreviewTableTwo";

const Application = () => {
    return (
        <AdminLayout>
            <>
                <div className="py-6">
                    <p className="text-teal font-semibold font-poppins text-2xl mb-4">Surname Firstname</p>
                </div>
                <div className="my-6 lg:my-6">
                    <p className="text-teal font-semibold font-poppins text-lg mb-5">Selected Programmes</p>
                    <div className="grid md:grid-cols-3 gap-5 lg:w-8/12">
                        <p className="flex items-center">
                            <GiDiploma fontSize="1.8rem" className="mr-3 text-gray-400" />
                            <span className="text-sm text-gray-600 text-bold">Blessing</span>
                        </p>
                        <p className="flex items-center">
                            <GiDiploma fontSize="1.8rem" className="mr-3 text-gray-400" />
                            <span className="text-sm text-gray-600 text-bold">Blessing</span>
                        </p>
                        <p className="flex items-center">
                            <GiDiploma fontSize="1.8rem" className="mr-3 text-gray-400" />
                            <span className="text-sm text-gray-600 text-bold">Blessing</span>
                        </p>
                    </div>
                </div>
                <div className="my-6 lg:my-12">
                    <div className="grid md:grid-cols-2 gap-5 lg:w-6/12">
                        <div>
                            <p className="text-teal font-semibold font-poppins text-lg">Level:</p>
                            <span className="text-sm text-gray-600 text-bold">Blessing</span>
                        </div>
                        <div>
                            <p className="text-teal font-semibold font-poppins text-lg">Mode of Study:</p>
                            <span className="text-sm text-gray-600 text-bold">Blessing</span>
                        </div>
                    </div>
                </div>
                <div className="my-6 lg:my-12">
                    <p className="text-teal font-semibold font-poppins text-lg mb-5">Personal Information</p>
                    <div className="grid gap-5 md:grid-cols-3 md:gap-7 lg:gap-9 lg:w-10/12">
                        <p className="flex items-center">
                            <IoPersonOutline fontSize="1.7rem" className="mr-3 text-gray-600" />
                            <span className="text-sm text-gray-600 text-bold">Mr Blessing Mashaire</span>
                        </p>
                        <p className="flex items-center">
                            <BsCalendar4Week fontSize="1.7rem" className="mr-3 text-gray-600" />
                            <span className="text-sm text-gray-600 text-bold">Date of Birth: <b>19/12/1998</b></span>
                        </p>
                        <p className="flex items-center">
                            <FaGenderless fontSize="1.7rem" className="mr-3 text-gray-600" />
                            <span className="text-sm text-gray-600 text-bold">Gender: <b>Male</b></span>
                        </p>
                        <p className="flex items-center">
                            <GiBigDiamondRing fontSize="1.7rem" className="mr-3 text-gray-600" />
                            <span className="text-sm text-gray-600 text-bold">Marital Status: <b>Single</b></span>
                        </p>
                       
                        <p className="flex items-center">
                            <BsGlobe fontSize="1.7rem" className="mr-3 text-gray-600" />
                            <span className="text-sm text-gray-600 text-bold">Nationality: <b>Zimbabweam</b></span>
                        </p>
                        <p className="flex items-center">
                            <HiOutlineIdentification fontSize="1.7rem" className="mr-3 text-gray-600" />
                            <span className="text-sm text-gray-600 text-bold">I.D Number: <b>12-34567A90</b></span>
                        </p>
                        <p className="flex items-center">
                            <BsEnvelope fontSize="1.7rem" className="mr-3 text-gray-600" />
                            <span className="text-sm text-gray-600 text-bold">Email: <b>mail@mail.com</b></span>
                        </p>
                        <p className="flex items-center">
                            <BsPhone fontSize="1.7rem" className="mr-3 text-gray-600" />
                            <span className="text-sm text-gray-600 text-bold">Phone Number: <b>0777 777 777</b></span>
                        </p>
                        <p className="flex items-center">
                            <RiUserLocationLine fontSize="1.7rem" className="mr-3 text-gray-600" />
                            <span className="text-sm text-gray-600 text-bold">Address: <b>12 Address Number, Harare</b></span>
                        </p>
                    </div>
                </div>

                {/* {
                    true !== 0 && (
                        <>
                            <p className="text-teal font-semibold font-poppins text-lg mb-5">'O' level Examination Results</p>
                            <PreviewTableOne ordLevel={ordLevel} />
                        </>
                    )
                }
                {
                    true !== 0 && (
                        <>
                            <p className="text-teal font-semibold font-poppins text-lg mb-5">Tertiary/College Examination Results</p>
                            <PreviewTableTwo tertiary={tertiary} />
                        </>
                    )
                }
                {
                    true !== 0 && (
                        <>
                            <p className="text-teal font-semibold font-poppins text-lg mb-5">Work/Employment</p>
                            <PreviewTableThree work={work} />
                        </>
                    )
                }
                } */}
                <div className="my-6 lg:my-12">
                    <p className="text-teal font-semibold font-poppins text-lg mb-5">Document</p>
                    <a
                        href="https/"
                    >
                        <Button
                            variant="outlined"
                            startIcon={<CgFileDocument size="1.4rem" />}
                            size="sm"
                            color="primary"
                        >
                            Open Document
                        </Button>
                    </a>
                </div>

                <div className="my-6 lg:my-12">
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                            <p className="text-gray-500 font-semibold font-poppins text-md mb-3">Select a Programme if application is to be accpeted.</p>
                        </FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            row
                        >
                            <FormControlLabel value="Civil Engineering" control={<Radio />} label="Civil Engineering" />
                            <FormControlLabel value="Architectural Technology" control={<Radio />} label="Architectural Technology" />
                            <FormControlLabel value="Quantity Survey" control={<Radio />} label="Quantity Survey" />
                        </RadioGroup>
                    </FormControl>
                </div>

                <div className="flex mt-12 mb-6 gap-10 flex-wrap md:gap-x-20">
                    <Button
                        variant="outlined"
                        startIcon={<FaCheckCircle />}
                        size="md"
                        color="success"
                    >
                        Accept Application
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<DeleteForeverIcon />}
                        size="md"
                        color="error"
                    >
                        Decline Application
                    </Button>
                </div>

            </>
        </AdminLayout>
    );
}

export default Application;