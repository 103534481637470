import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FaTrash } from 'react-icons/fa';

const FileTable = ({ files, setFiles}) => {

    return ( 
        <TableContainer >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Selected File</TableCell>
                        <TableCell>Remove File</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{files.name}</TableCell>
                        <TableCell>
                            <FaTrash 
                                color="#af0e19" 
                                onClick={() => setFiles('')}
                                className="cursor-pointer"
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
     );
}
 
export default FileTable;