import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableThree from './tables/TableThree';

const PageFive = ({ mode, work, setWork, fromDate, setFromDate, toDate, setToDate, occupation, setOccupation, employerName, setEmployerName, workAddress, setWorkAddress }) => {

    const addWork = () => {

        if (fromDate !== '' && toDate !== '' && occupation !== '' && employerName !== '' && workAddress !== '') {

            let data = {
                fromDate,
                toDate,
                occupation,
                employerName,
                workAddress

            }

            setWork([...work, data]);
            setFromDate('');
            setToDate('');
            setOccupation('');
            setEmployerName('');
            setWorkAddress('');
        } else {
            toast.error('Please provide all the details.');
        }
    }

    useEffect(() => {
        if (mode === "Full Time" || mode === "Distance") {
            toast.success('For the mode of study that you have selected you do not have to provide Employment details, hence you can opt to skip this step.');
        }
    }, [])


    return (
        <>
            <div className="py-6">
                <p className="text-teal font-semibold font-poppins text-2xl mb-4">Work / Employment</p>
            </div>
            <div className="grid gap-10 sm:grid-cols-3 md:gap-16 lg:gap-x-24">
                <TextField
                    id="standard-basic"
                    label="From Date: (e.g January 2020)"
                    variant="standard"
                    className=""
                    type="text"
                    onChange={(e) => setFromDate(e.target.value)}
                    value={fromDate}
                />
                <TextField
                    id="standard-basic"
                    label="To Date: (e.g June 2020/Current)"
                    variant="standard"
                    className=""
                    type="text"
                    onChange={(e) => setToDate(e.target.value)}
                    value={toDate}
                />
                <TextField
                    id="standard-basic"
                    label="Occupation / Job Title"
                    variant="standard"
                    className="s"
                    type="text"
                    onChange={(e) => setOccupation(e.target.value)}
                    value={occupation}
                />
            </div>
            <div className="grid gap-10 items-end sm:grid-cols-2 md:gap-16 lg:grid-cols-4 lg:gap-x-24 mt-10">
                <TextField
                    id="standard-basic"
                    label="Employer"
                    variant="standard"
                    type="text"
                    onChange={(e) => setEmployerName(e.target.value)}
                    value={employerName}
                />
                <TextField
                    type="text"
                    label="Work Address"
                    variant="standard"
                    className="col-span-2"
                    multiline
                    rows={2}
                    onChange={(e) => setWorkAddress(e.target.value)}
                    value={workAddress}
                />
            </div>
            <div className="mt-12 mb-6">
                <Button
                    variant="outlined"
                    startIcon={<AddCircleOutline />}
                    size="sm"
                    onClick={addWork}
                    color="primary"
                >
                    Add Work Record
                </Button>
            </div>
            {
                work.length !== 0 && <TableThree work={work} setWork={setWork} />
            }
            <ToastContainer />
        </>
    );
}

export default PageFive;