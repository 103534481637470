import React from 'react'
import AdminLayout from './AdminLayout'
import AcceptedTable from './tables/AcceptedTable'

function ApplicationAccepted() {
  return (
    <AdminLayout>
      <div className="py-6">
        <p className="text-teal font-semibold font-poppins text-2xl mb-4">Accepted Applications</p>
      </div>
      <AcceptedTable />
    </AdminLayout>
  )
}

export default ApplicationAccepted