import React from 'react';
import { useState, useEffect } from "react";
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import PageOne from "./components/PageOne";
import PageTwo from "./components/PageTwo";
import Steps from "./components/Steps";
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import * as EmailValidator from 'email-validator';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageThree from './components/PageThree';
import PageFour from './components/PageFour';
import PageFive from './components/PageFive';
import PageSix from './components/PageSix';
import PreviewApplication from './components/PreviewApplication';
import { FaCheckCircle } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db, storage } from './firebase/config';
import Modal from './components/previewtables/Modal';
import { collection, addDoc } from "firebase/firestore"; 
import { useNavigate } from "react-router-dom";

const Layout = () => {
    const [nextBtn, setNextBtn] = useState(false);

    const [programmeOne, setProgrammeOne] = useState('');
    const [programmeTwo, setProgrammeTwo] = useState('');
    const [programmeThree, setProgrammeThree] = useState('');
    const [mode, setMode] = useState('');
    const [level, setLevel] = useState('National Foundation Certificate');
    const [intake, setIntake] = useState('January 2023');

    const [title, setTitle] = useState('');
    const [forenames, setForeNames] = useState('');
    const [surname, setSurname] = useState('');
    const [dob, setDob] = useState('');
    const [gender, setGender] = useState('');
    const [maritalStatus, setMaritalStatus] = useState('');
    const [placeOfBirth, setPlaceOfBirth] = useState('');
    const [nationality, setNationality] = useState('');
    const [citizenship, setCitizenship] = useState('');
    const [idNumber, setIdNumber] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');

    const [ordLevel, setOrdLevel] = useState([]);
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [subject, setSubject] = useState('');
    const [board, setBoard] = useState('');
    const [grade, setGrade] = useState('');

    const [tertiary, setTertiary] = useState([]);
    const [tertiaryLevel, setTertiaryLevel] = useState('');
    const [course, setCourse] = useState('');
    const [tertiaryYear, setTertiaryYear] = useState('');

    const [work, setWork] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [occupation, setOccupation] = useState('');
    const [employerName, setEmployerName] = useState('');
    const [workAddress, setWorkAddress] = useState('');

    const [files, setFiles] = useState(null);
    const [error, setError] = useState(null);

    const [progressOne, setProgressOne] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [docSent, setDocSent] = useState(false);

    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const navigate = useNavigate();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // Create the file metadata
    /** @type {any} */
    const metadata = {
        contentType: 'application/pdf'
    };

    const completeApplication = async (data) => {
        try {
            const docRef = await addDoc(collection(db, "applications"), data);
            setOpenModal(false);
            toast.success("Thank you for applying we will notify you via email if your Application has been accepted. You will be redirecting to the home page in couple of seconds.");
            
            //REDIRECT TO COMPANY PAGE
            setTimeout(() => {
                window.location.assign("https://biccet.ac.zw/online-application");
            }, 6000);
          } catch (e) {
            setProgressOne(0);
            setDocSent(false)

            setOpenModal(false);

            toast.error('Error sending, please try agian!');
          }
    }


    const submitApplication = () => {
        setOpenModal(true);

        // storage references
        const storageRef = ref(storage, `applications/${files.name + idNumber}`);
        const uploadTask = uploadBytesResumable(storageRef, files, metadata);

        uploadTask.on('state_changed',
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                setProgressOne((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            },
            (err) => {
                switch (err.code) {
                    case 'storage/unauthorized':
                        toast.error("User doesn't have permission to access the object");
                        break;
                    case 'storage/canceled':
                        toast.error("User canceled the upload");
                        break;
                    case 'storage/unknown':
                        toast.error("Unknown error occurred, inspect error.serverResponse");
                        break;
                }
            }, () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    const uploadedFileUrl = downloadURL;
                    setDocSent(true)

                    let data = {
                        intake,
                        programmeOne,
                        programmeTwo,
                        programmeThree,
                        mode,
                        level,
                        title,
                        forenames,
                        surname,
                        dob,
                        gender,
                        maritalStatus,
                        nationality,
                        idNumber,
                        email,
                        phoneNumber,
                        address,
                        ordLevel,
                        tertiary,
                        work,
                        applicationStatus: 'pending',
                        uploadedFileUrl,
                        time: new Date()
                    }

                    completeApplication(data);
                });
            }
        );
    }


    useEffect(() => {

        //check info form data 
        if (programmeOne !== '' && programmeTwo !== '' && programmeThree !== '' && mode !== '' && level !== '' &&  intake !== '' && activeStep === 0) {
            setNextBtn(true);
            return;
        } else {
            setNextBtn(false);
        }

        if (title !== '' && forenames !== '' && surname !== '' && dob !== '' && gender !== '' && maritalStatus !== '' && forenames !== '' && nationality !== '' && idNumber !== '' && email !== '' && phoneNumber !== '' && address !== '' && activeStep === 1) {
            if (EmailValidator.validate(email)) {
                setNextBtn(true);
                return;
            } else {
                toast.warning("Invalid Gmail address.");
                return;
            }
        } else {
            setNextBtn(false);
        }

        if (level === "National Foundation Certificate" && activeStep === 2) {
            setNextBtn(true);
            return;
        } else {
            if (ordLevel.length >= 5 && activeStep === 2) {
                setNextBtn(true);
                return;
            } else {
                setNextBtn(false);
            }
        }

        if (level !== "National Diploma" && activeStep === 3) {
            setNextBtn(true);
            return;
        } else {
            if (tertiary.length !== 0 && activeStep === 3) {
                setNextBtn(true);
                return;
            } else {
                setNextBtn(false);
            }
        }

        if (mode === "Full Time" && activeStep === 4) {
            setNextBtn(true);
            return;
        } else {
            if (work.length !== 0 && activeStep === 4) {
                setNextBtn(true);
                return;
            } else {
                setNextBtn(false);
            }
        }

        if (files !== null) {
            if (files.length !== 0 && activeStep === 5) {
                setNextBtn(true);
            } else {
                setNextBtn(false);
            }
        } else {
            setNextBtn(false);
        }

    }, [ intake, programmeOne, programmeTwo, programmeThree, mode, level, title, forenames, surname, dob, gender, maritalStatus, placeOfBirth, nationality, citizenship, idNumber, email, phoneNumber, address, ordLevel, tertiaryLevel, tertiaryYear, course, tertiary, activeStep, work, files])


    return (
        <>
              <Helmet>
                <meta
                    name="description"
                    content="BICCET is Registered with the Ministry of Higher And Tertiary Education, Science and Technology Development. Enroll Today."
                />
                <title>BICCET | Online Application</title>
            </Helmet>
            <Nav />
            <div className="container py-10 hidden md:block">
                <Steps progress={activeStep} />
            </div>
            <div className="container md:shadow-lg md:py-10">
                <form>
                    {activeStep === 0 && <PageOne intake={intake} setIntake={setIntake} mode={mode} level={level} programmeOne={programmeOne} programmeTwo={programmeTwo} programmeThree={programmeThree} setProgrammeOne={setProgrammeOne} setProgrammeTwo={setProgrammeTwo} setProgrammeThree={setProgrammeThree} setMode={setMode} setLevel={setLevel} />}
                    {activeStep === 1 && <PageTwo title={title} setTitle={setTitle} forenames={forenames} setForeNames={setForeNames} surname={surname} setSurname={setSurname} dob={dob} setDob={setDob} gender={gender} setGender={setGender} maritalStatus={maritalStatus} setMaritalStatus={setMaritalStatus} placeOfBirth={placeOfBirth} setPlaceOfBirth={setPlaceOfBirth} nationality={nationality} setNationality={setNationality} citizenship={citizenship} setCitizenship={setCitizenship} idNumber={idNumber} setIdNumber={setIdNumber} email={email} setEmail={setEmail} phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} address={address} setAddress={setAddress} />}
                    {activeStep === 2 && <PageThree level={level} ordLevel={ordLevel} setOrdLevel={setOrdLevel} month={month} setMonth={setMonth} year={year} setYear={setYear} subject={subject} setSubject={setSubject} board={board} setBoard={setBoard} grade={grade} setGrade={setGrade} />}
                    {activeStep === 3 && <PageFour level={level} tertiary={tertiary} setTertiary={setTertiary} tertiaryLevel={tertiaryLevel} setTertiaryLevel={setTertiaryLevel} course={course} setCourse={setCourse} tertiaryYear={tertiaryYear} setTertiaryYear={setTertiaryYear} />}
                    {activeStep === 4 && <PageFive mode={mode} work={work} setWork={setWork} fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate} occupation={occupation} setOccupation={setOccupation} employerName={employerName} setEmployerName={setEmployerName} workAddress={workAddress} setWorkAddress={setWorkAddress} />}
                    {activeStep === 5 && <PageSix files={files} setFiles={setFiles} error={error} setError={setError} />}
                    {activeStep === 6 && <PreviewApplication intake={intake} mode={mode} level={level} programmeOne={programmeOne} programmeTwo={programmeTwo} programmeThree={programmeThree}
                        setTitle={setTitle} forenames={forenames} setForeNames={setForeNames} surname={surname} dob={dob} gender={gender} maritalStatus={maritalStatus}
                        nationality={nationality} idNumber={idNumber} email={email} phoneNumber={phoneNumber} address={address}
                        ordLevel={ordLevel} tertiary={tertiary} work={work} files={files} />}
                    {/* complete application btn */}
                    {
                        activeStep === 6 && (
                            <div className="mt-12 mb-6">
                                <Button
                                    variant="outlined"
                                    startIcon={<FaCheckCircle size="1.4rem" className="mr-1" />}
                                    size="lg"
                                    onClick={submitApplication}
                                    color="primary"
                                >
                                    Complete Application
                                </Button>
                            </div>
                        )
                    }
                </form>
            </div>
            <div className="container py-10">
                <MobileStepper
                    variant="dots"
                    steps={7}
                    position="static"
                    activeStep={activeStep}
                    sx={{ maxWidth: 400, flexGrow: 1 }}
                    nextButton={
                        <Button size="small" onClick={handleNext} disabled={!nextBtn}>
                            Next
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                            Back
                        </Button>
                    }
                />
            </div>
            <Footer />
            <ToastContainer />
            {
                openModal && <Modal progressOne={progressOne} setOpenModal={setOpenModal} docSent={docSent} setDocSent={setDocSent} />
            }
        </>
    );
}


export default Layout;