import React from "react";
import ReactDOM from "react-dom/client";
import App from './App';
import "./index.css"
import { Provider } from "react-redux";
import store from "./redux/store"
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import app from "./firebase/config";
import { storage } from "./firebase/config";

const rrfConfig = {
  userProfile: "",
  useFirestoreForProfile: false,
};

const rrfProps = {
  firebase: app,
  config: rrfConfig,
  dispatch: store.dispatch,
  storage, //since we are using Firestore
};

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>,
)