import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const data = [
    {
        fullname: 'Blessing Mashaire',
        gender: 'Male',
        level: 'National Certificate',
        mode: 'Full Time',
        programme: 'Civil Engineering',
        idNumber: '12 A123456B89',
        email: 'mail@mail.co.zw',
        phoneNumber: '0771234567', 
        address: '01 Kumba, Guta, Zimbabwe'

    },
    {
        fullname: 'Blessing Mashaire',
        gender: 'Male',
        level: 'National Certificate',
        mode: 'Full Time',
        programme: 'Civil Engineering',
        idNumber: '12 A123456B89',
        email: 'mail@mail.co.zw',
        phoneNumber: '0771234567', 
        address: '01 Kumba, Guta, Zimbabwe'

    },
  

]

function AcceptedTable() {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className="bg-teal">
                    <TableRow>
                        <TableCell><span className="text-gray-100">Fullname</span></TableCell>
                        <TableCell><span className="text-gray-100">Gender</span></TableCell>
                        <TableCell><span className="text-gray-100">Course Level</span></TableCell>
                        <TableCell><span className="text-gray-100">Mode of Study</span></TableCell>
                        <TableCell><span className="text-gray-100">Programme</span></TableCell>
                        <TableCell><span className="text-gray-100">I.D Number</span></TableCell>
                        <TableCell><span className="text-gray-100">Email</span></TableCell>
                        <TableCell><span className="text-gray-100">Phone Number</span></TableCell>
                        <TableCell><span className="text-gray-100">Address</span></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { data && data.map((row, index) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.fullname}
                            </TableCell>
                            <TableCell>{row.gender}</TableCell>
                            <TableCell>{row.level}</TableCell>
                            <TableCell>{row.mode}</TableCell>
                            <TableCell><b>{row.programme}</b></TableCell>
                            <TableCell>{row.idNumber}</TableCell>
                            <TableCell>
                                <a href={`mailto:${row.email}`} className="hover:text-teal" >{row.email}</a>
                            </TableCell>
                            <TableCell>
                                <a href={`tel:${row.phoneNumber}`} className="hover:text-teal" >{row.phoneNumber}</a>
                            </TableCell>
                            <TableCell>{row.address}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default AcceptedTable
