import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableOne from './tables/TableOne';

function PageThree({ level, ordLevel, setOrdLevel, month, setMonth, year, setYear, subject, setSubject, board, setBoard, grade, setGrade }) {

    const addAcademic = () => {
        for (let i = 0; i < ordLevel.length; i++) {
            if (ordLevel[i].subject === subject) {
                toast.error('Sorry, you cannot duplicate subject.')
                return;
            }
        }

        if (month !== '' && year !== '' && subject !== '' && board !== '' && grade !== '') {
            let data = {
                month,
                year,
                subject,
                board,
                grade
            }

            setOrdLevel([...ordLevel, data]);
            setYear('');
            setSubject('');
            setBoard('');
            setMonth('');
            setGrade('');
        } else {
            toast.error('Please provide all the details.');

        }

    }

    useEffect(() => {
        if (level === "National Foundation Certificate") {
            toast.success('For National Foundation Certificate the requirement is the ability to read and write, hence you can opt to skip this step.');
        }
    }, [])

    return (
        <>
            <div className="py-6">
                <p className="text-teal font-semibold font-poppins text-2xl mb-4">'O' level Examination Results</p>
            </div>
            <div className="grid gap-10 sm:grid-cols-2 md:grid-cols-6 md:gap-6 lg:gap-x-14">
                <FormControl fullWidth variant="standard" className="col-span-1">
                    <InputLabel id="month">Month</InputLabel>
                    <Select
                        labelid="month"
                        id="month-select"
                        onChange={(e) => setMonth(e.target.value)}
                        value={month}
                        label="Month"
                        name="month"
                    >
                        <MenuItem value="January">January</MenuItem>
                        <MenuItem value="February">February</MenuItem>
                        <MenuItem value="March">March</MenuItem>
                        <MenuItem value="April">April</MenuItem>
                        <MenuItem value="May">May</MenuItem>
                        <MenuItem value="June">June</MenuItem>
                        <MenuItem value="July">July</MenuItem>
                        <MenuItem value="August">August</MenuItem>
                        <MenuItem value="September">September</MenuItem>
                        <MenuItem value="October">October</MenuItem>
                        <MenuItem value="November">November</MenuItem>
                        <MenuItem value="December">December</MenuItem>

                    </Select>
                </FormControl>
                <TextField
                    labelId="year"
                    id="year-select"
                    variant="standard"
                    onChange={(e) => setYear(e.target.value)}
                    value={year}
                    label="Year"
                    name="year"
                    type="text"
                    className="col-span-1"
                />
                <TextField
                    labelId="subject"
                    id="subject-select"
                    variant="standard"
                    onChange={(e) => setSubject(e.target.value)}
                    value={subject}
                    label="Subject"
                    name="subject"
                    type="text"
                    className="col-span-2"
                />
                <FormControl fullWidth variant="standard" className="col-span-1">
                    <InputLabel id="exam-board">Exam Board</InputLabel>
                    <Select
                        labelId="exam-board"
                        id="exam-board-select"
                        onChange={(e) => setBoard(e.target.value)}
                        value={board}
                        label="Exam Board"
                        name="exam-board"
                    >
                        <MenuItem value="ZIMSEC">ZIMSEC</MenuItem>
                        <MenuItem value="Cambridge">Cambridge</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth variant="standard" className="col-span-1">
                    <InputLabel id="grade">Grade</InputLabel>
                    <Select
                        labelId="grade"
                        id="grade-select"
                        onChange={(e) => setGrade(e.target.value)}
                        value={grade}
                        label="Grade"
                        name="grade"
                    >
                        <MenuItem value="A">A</MenuItem>
                        <MenuItem value="B">B</MenuItem>
                        <MenuItem value="C">C</MenuItem>
                        <MenuItem value="D">D</MenuItem>
                        <MenuItem value="E">E</MenuItem>
                    </Select>
                </FormControl>
            </div>

            <div className="mt-12 mb-6">
                <Button
                    variant="outlined"
                    startIcon={<AddCircleOutline />}
                    size="sm"
                    onClick={addAcademic}
                    color="primary"
                >
                    Add Subject
                </Button>
            </div>
            {
                ordLevel.length !== 0 && <TableOne ordLevel={ordLevel} setOrdLevel={setOrdLevel} />
            }
            <p className="text-sm text-gray-500 mt-14 text-bold">NB: You need at least 5 subjects to proceed to the next step.</p>
            <ToastContainer />
        </>
    )
}

export default PageThree