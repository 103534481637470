import { useState, useEffect } from 'react';
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase/config'; 
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as EmailValidator from 'email-validator';
import { Button, TextField } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { SET_ACTIVE_USER } from '../redux/slice/authSlice';

const SignIn = ({ signIn, authError }) => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [pwdToogle, setPwdToogle] = useState('Show Password');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tooglePwdVisibility = () => {
    let passwordField = document.getElementById('passwordField');
    if (pwdToogle === 'Show Password') {
      passwordField.setAttribute("type", "text");
      setPwdToogle('Hide Password');
    }
    if (pwdToogle === 'Hide Password') {
      passwordField.setAttribute("type", "password");
      setPwdToogle('Show Password');
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (email === '' || password === '') {
      toast.error('Please provide an email and a password.');
    } else {

      if (!EmailValidator.validate(email)) {
        toast.warning('Please provide a valid email.');
      } else {

        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;

          setEmail('')
          setPassword('')

          navigate("/dashboard");

        })
        .catch((error) => {
          const errorCode = error.code;
          toast.error(error.message);
        });
     
      }

    }
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if(user) {
          dispatch(SET_ACTIVE_USER({
            email: user.email,
            userID: user.uid
          }))
          navigate("/dashboard");
      }
    })
  }, [])

  useEffect(() => {
    if (authError) {
      document.getElementById('Btn').innerHTML = 'Log In';
      toast.warning(authError);

      toast.warning();
    }
  }, [authError]);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Life Long Polytechnic is Registered with the Ministry of Higher And Tertiary Education, Science and Technology Development."
        />
        <title>Log In</title>
      </Helmet>
      <div className="min-h-[100vh] flex items-center">
        <div className="container py-5">
          <div className="shadow-lg p-10 sm:p-16 md:p-24 lg:px-16 lg:py-12 md-w-2/3 lg:w-1/2 mx-auto">
            <form noValidate autoComplete="off" className="bb-none" onSubmit={handleSubmit}>
              <h4 className="text-xl md:text-2xl font-bold text-gray-500 my-5">Sign In</h4>
              <div className="my-5 sm:my-6 md:my-8">
                <TextField
                  type="email"
                  label="Email"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  className="focus:ring:0 focus:shadow-none focus:outline-none focus:ring-color-transparent focus:ring-shadow-0"
                />
              </div>
              <div className="my-5 sm:my-6 md:my-8">
                <TextField
                  type="password"
                  label="Password"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  id="passwordField"
                  autoComplete="false"
                />
              </div>
              {
                pwdToogle === 'Show Password' ? (
                  <div
                    className="text-sm my-3 cursor-pointer"
                    onClick={tooglePwdVisibility}
                  >
                    Show Password
                  </div>
                ) : (
                  <div
                    className="text-sm my-3 cursor-pointer"
                    onClick={tooglePwdVisibility}
                  >
                    Hide Password
                  </div>
                )
              }
              <Button

                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                id="Btn"
              >
                Sign In
              </Button>
              {/* <div className="flex justify-between mt-5">
                <p className="mb-2">
                  <Link to="/register" className="text-sm text-muted underline">Register Instead</Link>
                </p>
                <p className="mb-2">
                  <Link to="/reset-password" className="text-sm text-muted underline">Forgot Password ?</Link>
                </p>
              </div> */}
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}


export default SignIn;