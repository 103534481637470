import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const nfcCourses = [
    "Brick and Blocklaying",
    "Building & Woodwork Drawing & Design",
    "DrainLaying and Rural Sanitation",
    "Wood Machining"
]


function NfcProg({ programmeOne, setProgrammeOne }) {
    return (
        <FormControl sx={{ m: 1, minWidth: 120 }} variant="standard">
            <InputLabel htmlFor="grouped-select-one">Programme One</InputLabel>
            <Select
                defaultValue=""
                id="grouped-select-one" 
                label="Programme One"
                value={programmeOne}
                onChange={(e) => setProgrammeOne(e.target.value)}
            >
                <ListSubheader>Construction Engineering</ListSubheader>
                {
                    nfcCourses && nfcCourses.map(prog => (
                        <MenuItem value={prog} key={prog}>{prog}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

export default NfcProg;