import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const civil = [
    "Architectural Technolog",
    "Civil Engineering",
    "Quantity Surveying",
    "Surveying & Geomatics",
    "Urban and Regional Planning",
    "Valuation & Estate Management",
    "Water Resources & Irrigation Engineering",

]
const constr = [
    "Building Technology",
    "Carpentry and Joinery",
    "Painting and Decorating",
    "Plumbing and DrainLaying",
    "Wood Technology"
]

function NcProg({ programmeOne, setProgrammeOne }) {
    return (
        <FormControl sx={{ m: 1, minWidth: 120 }} variant="standard" >
            <InputLabel htmlFor="grouped-select-one">Programme One</InputLabel>
            <Select
                defaultValue=""
                id="grouped-select-one"
                label="Programme One"
                value={programmeOne}
                onChange={(e) => setProgrammeOne(e.target.value)}
            >
                <ListSubheader>Civil Engineering</ListSubheader>
                {
                    civil && civil.map(prog => (
                        <MenuItem value={prog} key={prog}>{prog}</MenuItem>
                    ))
                }
                <ListSubheader>Construction Engineering</ListSubheader>
                {
                    constr && constr.map(prog => (
                        <MenuItem value={prog} key={prog}>{prog}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

export default NcProg;