import React from 'react'
import { Link } from 'react-router-dom'
import PeopleIcon from '@mui/icons-material/People';
import ReceiptIcon from '@mui/icons-material/Receipt';

function Stats() {
  return (
    <>
        <p className="text-teal font-semibold font-poppins text-2xl mb-4">Statistics</p>
   
    <div className="grid gap-5 md:grid-cols-2 md:gap-x-10 lg:gap-12 lg:grid-cols-3 xl:grid-cols-4">
        <div className="bg-neutral-100 rounded-lg p-5 shadow-lg relative">
            <h6 className="font-bold text-md uppercase text-gray-700">APPLICATIONS</h6>
            <h6 className="text-3xl uppercase my-3  text-gray-700">700</h6>
            <Link 
                className="text-sm underline text-teal"
                to="/"
            >
                View All Users
            </Link>
            <div className="absolute bottom-5 right-5 bg-teal h-12 w-12 rounded-full flex items-center justify-center">
                <PeopleIcon  className="text-gray-200" />
            </div>
        </div>
        <div className="bg-neutral-100 rounded-lg p-5 shadow-lg relative">
            <h6 className="font-bold  text-md uppercase text-gray-700">Accepeted</h6>
            <h6 className="text-3xl uppercase my-3 text-gray-700">100</h6>
            <Link 
                className="text-sm underline text-teal"
                to="/"
            >
                View All Quotes
            </Link>
            <div className="absolute bottom-5 right-5 bg-teal h-12 w-12 rounded-full flex items-center justify-center">
                <ReceiptIcon className="text-gray-200" />
            </div>
        </div>
        <div className="bg-neutral-100 rounded-lg p-5 shadow-lg relative">
            <h6 className="font-bold  text-md uppercase text-gray-700">pending</h6>
            <h6 className="text-3xl uppercase my-3 text-gray-700">100</h6>
            <Link 
                className="text-sm underline text-teal"
                to="/"
            >
                View All Quotes
            </Link>
            <div className="absolute bottom-5 right-5 bg-teal h-12 w-12 rounded-full flex items-center justify-center">
                <ReceiptIcon className="text-gray-200" />
            </div>
        </div>
    </div>
    </>
  )
}

export default Stats