import Layout from "../Layout";
import { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NfcProg from "./programmes/NfcProg";
import NfcProgTwo from "./programmes/NfcProgTwo";
import NfcProgThree from "./programmes/NfcProgThree";
import NcProg from "./programmes/NcProg";
import NcProgTwo from "./programmes/NcProgTwo";
import NcProgThree from "./programmes/NcProgThree";
import NdProg from "./programmes/NdProg";
import NdProgTwo from "./programmes/NdProgTwo";
import NdProgThree from "./programmes/NdProgThree";


const PageOne = ({ mode, level, programmeOne, programmeTwo, programmeThree, intake, setIntake, setProgrammeOne, setProgrammeTwo, setProgrammeThree, setMode, setLevel }) => {


    useEffect(() => {

        //check if programmes are different
        if (programmeOne !== '' && programmeOne === programmeTwo) {
            toast.error('Please select different programme choices.');
            setProgrammeTwo('');
        } else if (programmeOne !== '' && programmeOne === programmeThree) {
            toast.error('Please select different programme choices.');
            setProgrammeThree('');
        } else if (programmeTwo !== '' && programmeTwo === programmeThree) {
            toast.error('Please select different programme choices.');
            setProgrammeThree('');
        }

        if (programmeOne !== '' && programmeTwo !== '' && programmeOne !== programmeThree && programmeOne !== programmeTwo && programmeTwo !== programmeThree) {
            toast.error(null);
        }

    }, [programmeOne, programmeTwo, programmeThree, setProgrammeTwo, setProgrammeThree]);

    return (
        <>
            {/* ================ Section One ============ */}
            <div className="py-6">
                <p className="text-teal font-semibold font-poppins text-2xl mb-4">Choice of Study Programme</p>
            </div>
            <div className="grid sm:grid-cols-2 gap-10 md:gap-x-16 md:w-2/3 lg:gap-x-20">
                <FormControl fullWidth variant="standard">
                    <InputLabel id="course-level">Course Level</InputLabel>
                    <Select
                        labelId="course-level"
                        id="course-level-select"
                        onChange={(e) => setLevel(e.target.value)}
                        value={level}
                        label="Course Level"
                        name="courseLevel"

                    >
                        <MenuItem value="National Foundation Certificate">National Foundation Certificate</MenuItem>
                        <MenuItem value="National Certificate">National Certificate</MenuItem>
                        <MenuItem value="National Diploma">National Diploma</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth variant="standard">
                    <InputLabel id="intake">Intake</InputLabel>
                    <Select
                        labelId="intake"
                        id="intake-select"
                        onChange={(e) => setIntake(e.target.value)}
                        value={intake}
                        label="Intake"
                    >
                        <MenuItem value="January 2023">January 2023</MenuItem>
                        <MenuItem value="May 2023">May 2023</MenuItem>
                    </Select>
                </FormControl>
            </div>

            {/* ================ Section Two ============ */}

            <div className="pt-14">
                <p className="text-teal font-poppins text-xl mb-4">Programmes:</p>
            </div>
            {
                level === "National Foundation Certificate" && (
                    <div className="grid gap-7 md:grid-cols-2 md:gap-16 lg:grid-cols-3 lg:gap-x-24">
                        <NfcProg programmeOne={programmeOne} setProgrammeOne={setProgrammeOne} />
                        <NfcProgTwo programmeTwo={programmeTwo} setProgrammeTwo={setProgrammeTwo} />
                        <NfcProgThree programmeThree={programmeThree} setProgrammeThree={setProgrammeThree} />
                    </div>
                )
            }
            {
                level === "National Certificate" && (
                    <div className="grid gap-7 md:grid-cols-2 md:gap-16 lg:grid-cols-3 lg:gap-x-24">
                        <NcProg programmeOne={programmeOne} setProgrammeOne={setProgrammeOne} />
                        <NcProgTwo programmeTwo={programmeTwo} setProgrammeTwo={setProgrammeTwo} />
                        <NcProgThree programmeThree={programmeThree} setProgrammeThree={setProgrammeThree} />
                    </div>
                )
            }
            {
                level === "National Diploma" && (
                    <div className="grid gap-7 md:grid-cols-2 md:gap-16 lg:grid-cols-3 lg:gap-x-24">
                        <NdProg programmeOne={programmeOne} setProgrammeOne={setProgrammeOne} />
                        <NdProgTwo programmeTwo={programmeTwo} setProgrammeTwo={setProgrammeTwo} />
                        <NdProgThree programmeThree={programmeThree} setProgrammeThree={setProgrammeThree} />
                    </div>
                )
            }

            {/* ================ Section Two ============ */}
            <div className="pt-14">
                <p className="text-teal font-poppins text-xl mb-4">Preferred Mode of Study:</p>
            </div>
            <div className="grid gap-x-6 md:w-1/2 lg:w-1/3">
                <FormControl fullWidth variant="standard">
                    <InputLabel id="mode-of-study">Mode of Study</InputLabel>
                    <Select
                        labelId="mode-of-study"
                        id="mode-of-study-select"
                        onChange={(e) => setMode(e.target.value)}
                        value={mode}
                        label="Mode of Study"
                        name="studyMode"
                    >
                        <MenuItem value="Full Time">Full Time</MenuItem>
                        <MenuItem value="Part Time">Part Time</MenuItem>
                        <MenuItem value="Block Release">Block Release</MenuItem>
                        <MenuItem value="Distance">Distance/Online</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <p className="text-sm text-gray-500 mt-14 text-bold">NB: All fields are required to procced to the next step.</p>
            <ToastContainer />
        </>

    );
}

export default PageOne;
