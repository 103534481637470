import React from 'react';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FileTable from './tables/FileTable';

const PageSix = ({error, files, setFiles, setError}) => {

    const changeHandler = (e) => {
        let selected = e.target.files[0];
        //console.log(selected);
        if (selected && selected.type === 'application/pdf') {
            if(selected.size > 5000000) {
                toast.error('File size is too large.');
            } else {
                //setFiles([...files, selected]);
                setFiles(selected);
            }
           // console.log(selected);
        } else {
            toast.error('Please select a valid PDF file.');

        }

    }

    return ( 
        <>
            <div className="py-6">
                <p className="text-teal font-semibold font-poppins text-2xl mb-4">Upload Documents</p>
            </div>
            <p className="text-sm text-gray-500 text-bold">Please provide the document to support your application.<br/><br/><b>Note:</b> Document should be in PDF format <b>only</b>. <br/>Please select a pdf which contain all your documents.</p>
            <div className="mt-10 mb-4">
                <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 dark:txt-gray-300" for="file_input">Upload File</label>
                <input 
                    type="file" id="file_input" 
                    className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border md:w-1/3 border-gray-300 cursor-pointer lg:w-1/4 dark:text-gray-400 focus:outlined-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" 
                    onChange={changeHandler} 
                    accept="application/pdf" 
                />
            </div>  
            {
                files && (
                    <div className="lg:w-1/2 mt-10">
                        {
                            files.length !== 0 && <FileTable files={files} setFiles={setFiles}/>
                        } 
                    </div>
                )
            }     

        </>
     );
}
 
export default PageSix;