import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PendingIcon from '@mui/icons-material/Pending';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Chip } from '@mui/material';
import { Link } from 'react-router-dom';

const data = [
    {
        fullname: 'Blessing Mashaire',
        gender: 'Male',
        level: 'National Certificate',
        mode: 'Full Time',
        status: 'pending',
        programmes:
        {
            progOne: 'Civil Enginnering',
            progTwo: 'Civil Enginnering',
            progThree: 'Civil Enginnering'
        }
    },
    {
        fullname: 'Blessing Mashaire',
        gender: 'Male',
        level: 'National Certificate',
        mode: 'Full Time',
        status: 'pending',
        programmes: {
            progOne: 'Civil Enginnering',
            progTwo: 'Civil Enginnering',
            progThree: 'Civil Enginnering'
        }
    },

]

function Row({ row }) {
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    {
                        row.status !== 'accepted' && (
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpen(!open)}
                            >
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        )}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.fullname}
                </TableCell>
                <TableCell>{row.gender}</TableCell>
                <TableCell>{row.level}</TableCell>
                <TableCell>{row.mode}</TableCell>
                <TableCell>
                    <Link to="/application/1234">
                        <Button
                            variant="outlined"
                            color="primary"
                            size="sm"
                        >
                            Review
                        </Button>
                    </Link>
                </TableCell>
            </TableRow>
            {
                row.status !== 'accepted' && (
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 1 }}>
                                    <Typography variant="h6" gutterBottom component="div">
                                        Selected Programmes
                                    </Typography>
                                    <Table size="small" aria-label="purchases">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Programme 1</TableCell>
                                                <TableCell>Programme 2</TableCell>
                                                <TableCell>Programme 3</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {row.programmes.progOne}
                                                </TableCell>
                                                <TableCell>{row.programmes.progTwo}</TableCell>
                                                <TableCell>{row.programmes.progThree}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>

                )
            }

        </React.Fragment>
    );
}

export default function PendingTable() {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead className="bg-teal">
                    <TableRow>
                        <TableCell />
                        <TableCell><span className="text-lg font-bold text-gray-100">Fullname</span></TableCell>
                        <TableCell><span className="text-lg font-bold text-gray-100">Gender</span></TableCell>
                        <TableCell><span className="text-lg font-bold text-gray-100">Course Level</span></TableCell>
                        <TableCell><span className="text-lg font-bold text-gray-100">Mode of Study</span></TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data && data.map((row) => (
                            <Row key={row.name} row={row} />
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}
