import React from 'react'
import clsx from 'clsx';
import { Avatar, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useLocation } from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { IoMdLogOut } from 'react-icons/io';
import { FiCheckCircle } from 'react-icons/fi';
import { MdDashboard, MdOutlinePending } from 'react-icons/md';
import { FaList } from 'react-icons/fa';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../firebase/config';
import { toast, ToastContainer } from 'react-toastify';
import { useEffect } from 'react';
import { REMOVE_ACTIVE_USER } from '../redux/slice/authSlice';
import { useDispatch } from 'react-redux';

import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    page: {
        width: '100%',
        backgroundColor: '#fff'
    },
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    // menuButton: {
    //     marginRight: theme.spacing(2),
    // },
    hide: {
        display: 'none',
    },
    drawer: {

        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    toolbar: theme.mixins.toolbar,
    name: {
        flexGrow: 1,
    },
    avatar: {
        marginLeft: theme.spacing(2),

    },
    makeWhite: {
        color: '#fff'
    },
    date: {
        marginLeft: theme.spacing(1),
        textAlign: "center",
        color: '#006a62',
        fontWeight: 'light'
    }

}));


const AdminLayout = ({ children }) => {
    const classes = useStyles()
    const location = useLocation()
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    var date = moment().format('LL');

    const menuItems = [
        {
            text: 'Dashboard',
            icon: <MdDashboard className="text-gray-500 ml-3" size="1.3rem" />,
            path: '/dashboard'
        },
        {
            text: 'Applications',
            icon: <FaList className="text-gray-500 ml-3" size="1.4rem" />,
            path: '/applications'
        },
        {
            text: 'Accepted Applications',
            icon: <FiCheckCircle className="text-gray-500 ml-3" size="1.5rem" />,
            path: '/applications-accepted'
        },
        {
            text: 'Pending Applications',
            icon: <MdOutlinePending className="text-gray-500 ml-3" size="1.6rem" />,
            path: '/applications-pending'
        }
    ]

    useFirestoreConnect({
        collection: `applications`,
        storeAs: "applications",
    });
    
    const applications = useSelector((state) => state.firestore.data.applications);
    useSelector((state) => console.log(state));
    console.log(applications);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (!user) {
                dispatch(REMOVE_ACTIVE_USER())
                navigate("/login");
            }
        })

    }, [])

    return (
        <>
            {
                true && (
                    <>
                        <Helmet>
                            <meta
                                name="description"
                                content="Life Long Polytechnic"
                            />
                            <title>BICCET | Admin Portal</title>
                        </Helmet>
                        <div className={classes.root}>
                            <CssBaseline />
                            {/* start app bar */}
                            <AppBar
                                elevation={0}
                                position="fixed"
                                className={clsx(classes.appBar, {
                                    [classes.appBarShift]: open,
                                })}
                                color="primary"
                            >
                                <div className="container">
                                    <Toolbar>
                                        <IconButton
                                            color="inherit"
                                            aria-label="open drawer"
                                            onClick={handleDrawerOpen}
                                            edge="start"
                                            className={clsx(classes.menuButton, open && classes.hide)}

                                        >
                                            <MenuIcon />
                                        </IconButton>

                                        {
                                            !open && (
                                                <>
                                                    <Link to="/portal-dashboard" className={classes.name}>
                                                        <Typography variant="h6" className="pl-3 font-normal">
                                                            BICCET <span className="text-light text-bkg">Admin</span>
                                                        </Typography>
                                                    </Link>
                                                    <Typography className="text-bkg">
                                                        admin@biccet.ac.zw
                                                    </Typography>
                                                    <Avatar
                                                        src='https://tailwindui.com/img/ecommerce-images/product-page-02-tertiary-product-shot-02.jpg'
                                                        className={classes.avatar}
                                                    />
                                                </>
                                            )
                                        }
                                    </Toolbar>
                                </div>
                            </AppBar>
                            {/* end app bar */}

                            {/* start drawer */}
                            <Drawer
                                className={classes.drawer}
                                variant="persistent"
                                anchor="left"
                                open={open}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                            >
                                <div className={classes.drawerHeader}>
                                    <IconButton onClick={handleDrawerClose}>
                                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                                    </IconButton>
                                </div>
                                {/* links/list section */}
                                <Typography variant="subtitle2" className={classes.date}>
                                    {date}
                                </Typography>
                                <List>
                                    {menuItems.map((item) => (
                                        <Link
                                            key={item.text}
                                            to={item.path}
                                        >
                                            <ListItem
                                                button

                                                //onClick={() => history.push(item.path)}
                                                className={location.pathname === item.path ? classes.active : null}
                                            >
                                                <ListItemIcon>{item.icon}</ListItemIcon>
                                                <ListItemText primary={item.text} />
                                            </ListItem>
                                        </Link>
                                    ))}
                                    <ListItem
                                        button
                                        onClick={() => {
                                            signOut(auth).then(() => {
                                                // Sign-out successful.
                                            }).catch((error) => {
                                                // An error happened.
                                                toast.success(error.message)
                                            });
                                        }}
                                    >
                                        <ListItemIcon>
                                            <IoMdLogOut className="text-gray-500 ml-3" size="1.6rem" />
                                        </ListItemIcon>
                                        <ListItemText primary='Log Out' />
                                    </ListItem>
                                </List>
                            </Drawer>
                            {/* end drawer */}

                            {/* main */}
                            <div className={classes.page} >
                                <div className={classes.toolbar}></div>
                                <div className="p-6">
                                    <div className="container">
                                        {children}
                                    </div>
                                </div>
                            </div>
                            {/* end main */}
                        </div>
                        <ToastContainer />
                    </>
                )
            }
        </>
    );
}

export default AdminLayout;